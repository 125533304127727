<template>
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox">
        <div title="考务任务名称" class="searchboxItem ci-full">
          <span class="itemLabel" style="width: 150px;">考务任务名称:</span>
          <el-input v-model="retrievalDatas.projectName" type="text" size="small" placeholder="请输入考务任务名称" clearable />
        </div>
        <div class="df" style="padding-left: 10px">
          <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
          row-key="activityId" style="width: 100%" @selection-change="checkListData" :header-cell-style="tableHeader"
          stripe>
          <el-table-column label="选择项" align="center" width="60">
            <template slot-scope="scope">
              <el-radio-group v-model="tableRadio" @change="changeRadio(scope.row)">
                <el-radio :label="scope.row.activityId">{{ }}</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column label="序号" align="left" type="index" :index="indexMethod" width="55px" />
          <el-table-column label="考务任务名称" align="left" show-overflow-tooltip prop="activityName" min-width="100" />
          <el-table-column label="培训工种" align="left" show-overflow-tooltip prop="occupationName" min-width="80" />
          <el-table-column label="培训等级" align="left" show-overflow-tooltip prop="levelCode" min-width="80">
            <template slot-scope="scope">
              <span>{{
                $setDictionary("ET_TRAIN_LEVEL", scope.row.levelCode)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="报名人数" align="left" show-overflow-tooltip prop="personNum" min-width="80" />
          <el-table-column label="创建日期" align="left" prop="createTime" show-overflow-tooltip min-width="100">
            <template slot-scope="scope">
              {{ scope.row.createTimeStr }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" show-overflow-tooltip width="120">
            <template slot-scope="scope">
              <el-button type="text" style="padding:0px 5px" size="mini" @click="seeStudent(scope.row)">查看学员</el-button>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <!-- 弹框 - 查看学员 -->
    <el-dialog title="查看学员" :visible.sync="seeStudentListLoading" width="70%" top="30px" @close="seeStudentListLoading = false">
      <seeStudentList :occupationId="occupationId" :tabsName="tabsName" :activityId="activityId" v-if="seeStudentListLoading" />
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import seeStudentList from "./seeStudentList.vue";
export default {
  name: "operate_personnelDataCollectionJiLin_addData_2",
  components: {
    Empty,
    PageNum,
    seeStudentList,
  },
  mixins: [List],
  props: {
    // 培训工种id
    occupationId: {
      type: String,
      default: ""
    },
    // 当前tabs名称
    tabsName: {
      type: String,
      default: ""
    }
  },
  //页面初始化时不自动查询列表数据
  doNotInit: true,
  data() {
    return {
      // 页面检索数据
      retrievalDatas: {
        projectName: "", // 任务名称
      },
      // 选中列的id
      tableRadio: "",
      // 查看学员 - 考务id
      activityId: "",
      // 查看学员弹框状态
      seeStudentListLoading: false,
    };
  },
  computed: {
  },
  created() {
    this.getTableHeight();
  },
  mounted() { },
  watch: {
    tabsName: {
      handler(val, oldVal) {
        if (val == 'addData_2') {
          this.getData();
        }
      },
      // 深度观察监听
      immediate: true,
    },
  },
  methods: {
    // 获取 - 列表
    getData(pageNum = 1) {
      // 有培训工种id
      if (this.occupationId) {
        const params = {
          pageNum: pageNum,
          pageSize: this.pageSize,
          occupationId: this.occupationId,
          type: '20', // 考务任务
        };
        // 任务名称
        if (this.retrievalDatas.projectName) {
          params.projectName = this.retrievalDatas.projectName;
        }
        this.doFetch({
          url: "/biz/ct/integration/selectTaskList",
          params,
          pageNum,
        });
      } else {
        this.$message({
          message: "请先选择培训工种",
          type: "warning"
        });
      }

    },
    // 点击列表单选事件
    changeRadio(row) {
      this.$emit("eventBus", '2', row);
    },
    // 删除列表单选事件
    deleteListRadio() {
      this.tableRadio = "";
    },
    // 查看学员
    seeStudent(row) {
      this.activityId = row.activityId;
      this.seeStudentListLoading = true;
    },
    // 重置 data 中要重置数据
    allDeleteData() {
      // 处理混入数据
      this.pageSize = this.$options.data().pageSize;
      this.pageNum = this.$options.data().pageNum;
      this.apiData = this.$options.data().apiData;
      this.tableData = this.$options.data().tableData;
      this.tableRadio = "";
      // 处理当前页面数据
      this.retrievalDatas = this.$options.data().retrievalDatas;
      this.getData();
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16 - 120;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped></style>
