<template>
  <!-- 重构 - 结算管理 - 待结算列表 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">人员数据汇集</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">新增</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="培训工种" class="searchboxItem ci-full">
              <span class="itemLabel">培训工种:</span>
              <el-select v-model="retrievalDatas.occupationId" placeholder="请选择培训工种" size="small"
                @change="changeOccupationId">
                <el-option v-for="item in occupationList" :key="item.occupationId" :label="item.occupationName"
                  :value="item.occupationId">
                  <span style="float: left">{{ item.occupationName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.trainLevel }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="业务融合任务名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 200px;">业务融合任务名称:</span>
              <el-input v-model="retrievalDatas.integrationName" type="text" size="small" placeholder="请输入业务融合任务名称" clearable />
            </div>
          </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="tabsChange">
          <el-tab-pane label="报名任务" name="addData_1">
            <addData_1 ref="addData_1" :tabsName="activeName" :occupationId="retrievalDatas.occupationId"
              @eventBus="pageEventBus" />
          </el-tab-pane>
          <el-tab-pane label="考务任务" name="addData_2">
            <addData_2 ref="addData_2" :tabsName="activeName" :occupationId="retrievalDatas.occupationId"
              @eventBus="pageEventBus" />
          </el-tab-pane>
          <el-tab-pane label="制证任务" name="addData_3">
            <addData_3 ref="addData_3" :tabsName="activeName" :occupationId="retrievalDatas.occupationId"
              @eventBus="pageEventBus" />
          </el-tab-pane>
        </el-tabs>
        <div class="yxrwnc">
          <div class="y1">
            <p><span class="yxrwnc_1">已选任务名称：</span><span class="yxrwnc_2"></span></p>
            <p><span class="yxrwnc_1">报名任务：</span><span class="yxrwnc_2">{{ selectedTaskName.addData_1_data.projectName
            }}</span><i class="el-icon-delete" v-if="selectedTaskName.addData_1_data.projectName"
                @click="deleteTaskData(1)"></i></p>
            <p><span class="yxrwnc_1">考务任务：</span><span class="yxrwnc_2">{{ selectedTaskName.addData_2_data.activityName
            }}</span><i class="el-icon-delete" v-if="selectedTaskName.addData_2_data.activityName"
                @click="deleteTaskData(2)"></i></p>
            <p><span class="yxrwnc_1">制证任务：</span><span class="yxrwnc_2">{{ selectedTaskName.addData_3_data.taskExplain
            }}</span><i class="el-icon-delete" v-if="selectedTaskName.addData_3_data.taskExplain"
                @click="deleteTaskData(3)"></i></p>
          </div>
          <div class="y2">
            <el-button type="primary" class="bgc-bv" size="small" round @click="$router.back()">取消</el-button>
            <el-button type="primary" class="bgc-bv" size="small" round @click="addTask()">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import addData_1 from "@/views/operate/personnelDataCollectionJiLin/addData_1";
import addData_2 from "@/views/operate/personnelDataCollectionJiLin/addData_2";
import addData_3 from "@/views/operate/personnelDataCollectionJiLin/addData_3";
export default {
  name: "operate_personnelDataCollectionJiLin_addData",
  components: {
    addData_1,
    addData_2,
    addData_3,
  },
  mixins: [List],
  data() {
    return {
      // tabs当年的name
      activeName: "addData_1",
      // 页面检索数据
      retrievalDatas: {
        occupationId: "", // 培训工种id
        integrationName: "", // 业务融合任务名称
      },
      // 培训工种 - 拉下数据
      occupationList: [],
      // 已选任务名称
      selectedTaskName: {
        addData_1_data: {}, // 报名任务 - 数据
        addData_2_data: {}, // 考务任务 - 数据
        addData_3_data: {}, // 制证任务 - 数据
      }
    };
  },
  watch: {

  },
  created() {
    this.searchCompOccupation();
  },
  mounted() { },
  methods: {
    // 获取 - 培训工种
    searchCompOccupation() {
      this.$post('/biz/ct/occupation/select/pageList', {
        occupationName: ""
      }).then(ret => {
        this.occupationList = ret.data || []
      }).catch(err => {
        return;
      })
    },
    // 选择 - 培训工种下拉事件
    changeOccupationId(val) {
      this.selectedTaskName = this.$options.data().selectedTaskName;
      setTimeout(() => {
        if (this.$refs.addData_1) this.$refs.addData_1.allDeleteData();
        if (this.$refs.addData_2) this.$refs.addData_2.allDeleteData();
        if (this.$refs.addData_3) this.$refs.addData_3.allDeleteData();
      }, 300);
      for (let i = 0; i < this.occupationList.length; i++) {
        if(this.occupationList[i].occupationId == val){
          this.retrievalDatas.integrationName = this.occupationList[i].occupationName + '-' + this.occupationList[i].trainLevel + '-' + new Date(new Date().getTime()).Format('yy-MM-dd');
        }
      }
    },
    // 选项卡的Change事件
    tabsChange(e) {
      this.activeName = e.name;
    },
    // 回传 - 子页面选择列表数据
    pageEventBus(type, row) {
      this.selectedTaskName['addData_' + type + '_data'] = row;
    },
    // 删除已选任务名称
    deleteTaskData(type) {
      this.$refs['addData_' + type].deleteListRadio();
      this.selectedTaskName['addData_' + type + '_data'] = {};
    },
    // 确定添加任务
    addTask() {
      // 报名任务 && 业务融合任务名称
      if(!this.retrievalDatas.integrationName){
        this.$message({
          message: "请填写：业务融合业务名称",
          type: "warning"
        });
        return
      } else if(!Object.values(this.selectedTaskName.addData_1_data).length){
        this.$message({
          message: "请选择:报名任务",
          type: "warning"
        });
        return
      } else { 
        this.$post('/biz/ct/integration/insert', {
          occupationId: this.retrievalDatas.occupationId,
          integrationName: this.retrievalDatas.integrationName,
          projectId: this.selectedTaskName.addData_1_data.projectId,
          activityId: this.selectedTaskName.addData_2_data.activityId,
          taskId: this.selectedTaskName.addData_3_data.taskId,
        }).then(res => {
          this.$router.back();
          this.$message.success("操作成功");
        }).catch(err => {
          return;
        })
      }
    },
  },
};
</script>
<style lang="less" scoped>
.yxrwnc {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background: #f3f3f3;
  display: flex;
  justify-content: space-between;

  .y1 {
    p {
      line-height: 25px;

      .yxrwnc_1 {
        width: 200px;
        text-align: right;
      }

      .yxrwnc_2 {
        color: #409EFF;
      }

      i:hover {
        color: red;
        cursor: pointer;
      }
    }
  }

  .y2 {
    padding-top: 60px;
  }

}
</style>
