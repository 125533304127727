<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="retrievalDatas.userName" clearable size="small" placeholder="请输入姓名"></el-input>
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="retrievalDatas.idcard" clearable size="small" placeholder="请输入身份证号"></el-input>
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="retrievalDatas.mobile" clearable size="small" placeholder="请输入手机号"></el-input>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" width="100px" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" min-width="100" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" min-width="150" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" min-width="120" />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";

export default {
  name: "operate_personnelDataCollectionJiLin_index",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: {
    // 培训工种id
    occupationId: {
      type: String,
      default: ""
    },
    // 当前tabs名称
    tabsName: {
      type: String,
      default: ""
    },
    // 报名任务di
    projectId: {
      type: String,
      default: ""
    },
    // 考务任务id
    activityId: {
      type: String,
      default: ""
    },
    // 制证任务id
    taskId: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      // 页面检索数据
      retrievalDatas: {
        userName: '', // 姓名
        idcard: '', // 身份证号
        mobile: '', // 手机号
      },
    };
  },
  created() {

  },
  mounted() {
  },
  computed: {

  },
  watch: {

  },
  methods: {
    // 获取 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        occupationId: this.occupationId,
        type: this.zF.someoneString(this.tabsName,this.tabsName.length - 1) + '0', // tap选项卡name的最后一位字符串 + '0';也就是'10'的意思
      };
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      if (this.activityId) {
        params.activityId = this.activityId;
      }
      if (this.taskId) {
        params.taskId = this.taskId;
      }
      if (this.retrievalDatas.userName) {
        params.userName = this.retrievalDatas.userName;
      }
      if (this.retrievalDatas.idcard) {
        params.idcard = this.retrievalDatas.idcard;
      }
      if (this.retrievalDatas.mobile) {
        params.mobile = this.retrievalDatas.mobile;
      }
      this.doFetch({
        url: "/biz/ct/integration/selectPersonList",
        params,
        pageNum
      });
    },
  }
};
</script>
<style lang="less" scoped></style>
